export const common = {
  save: 'Simpan',
  cancel: 'Batal',
  title: 'Judul',
  option: 'Opsi',
  optional: 'opsional',
  maximum: 'Maksimum',
  minimum: 'Minimum',
  exact: 'Persis',
  delete: 'Hapus',
  back: 'Kembali',
  edit: 'Ubah',
  loading: 'Loading',
  responses: 'Respons',
  feedback: 'Masukan',
  verify: 'Verifikasi',
  verified: 'Terverifikasi',
  goToDashboardCta: 'Kembali ke dashboard',
  download: 'Unduh',
  createForm: 'Buat formulir',
  actionsDropdown: {
    ariaLabel: 'Opsi lainnya',
    preview: 'Lihat formulir',
    duplicate: 'Duplikat',
    share: 'Kirim',
    manageAdmins: 'Kelola akses',
    moveToFolder: 'Pindahkan ke folder',
  },
  errors: {
    required: 'Input wajib diisi',
    validValue: 'Harap masukan {fieldName} yang valid',
    image: {
      notProvided: 'Gambar tidak disediakan',
    },
    validation: {
      mobileNoVerification: 'Wajib verifikasi nomor hp',
      emailVerification: 'Wajib verifikasi alamat email',
      homeNo: 'Harap masukkan nomor telpon yang valid',
    },
    pageNotFound: 'Halaman ini tidak ditemukan.',
  },
  tooltip: {
    deleteField: 'Hapus pertanyaan',
    duplicateField: 'Duplikat pertanyaan',
  },
  dropdown: {
    placeholder: 'Pilih opsi',
  },
  days: {
    Mondays: 'Senin',
    Tuesdays: 'Selasa',
    Wednesdays: 'Rabu',
    Thursdays: 'Kamis',
    Fridays: 'Jumat',
    Saturdays: 'Sabtu',
    Sundays: 'Minggu',
  },
  today: 'hari ini',
  tomorrow: 'besok',
  yesterday: 'kemarin',
  formStatus: {
    closed: 'Ditutup',
    open: 'Dibuka',
  },
  responseMode: {
    email: 'Mode email',
    storage: 'Mode disimpan',
  },
  formName: 'Nama formulir',
  editForm: {
    text: 'Edit formulir',
    ariaLabel: 'Klik untuk edit formulir',
  },
  formSubmission: {
    disabled: 'Submisi dinonaktifkan',
    enabled: 'Kirim respons',
    submitting: 'Mengirim',
  },
  feedbackSubmission: {
    title: 'Bagaimana pengalaman Anda mengisi formulir ini?',
    ratingRequired: 'Wajib masukkan penilaian Anda',
    feedbackPlaceholder:
      'Beritahu kami bagaimana pengalaman Anda mengisi formulir ini',
    cta: 'Kirim penilaian',
  },
  responsesResult: {
    title: '{count} respons yang telah diterima',
    secretKey: {
      inputName: 'Masukkan atau unggah kunci rahasia',
      inputDescription:
        'Kunci rahasia yang diunduh saat Anda membuat formulir ini.',
      cta: 'Buka respons',
      missingSecretKeyCta: 'Kehilangan kunci rahasia?',
      incorrectSecretKeyFile: 'File kunci rahasia invalid',
      incorrectSecretKey: 'Kunci rahasia yang dimasukan invalid',
      required: 'Wajib memasukan kunci rahasia formulir',
    },
    email: {
      info: 'Maaf, FormID tidak menyimpan respons pada mode email. Harap hubungi kami untuk pertanyaan lebih lanjut.',
    },
  },
  filteredResult: {
    title: '{count} hasil ditemukan',
  },
}
